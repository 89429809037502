@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	font-family: 'Lexend', -apple-system, BlinkMacSystemFont, Arial, Helvetica, sans-serif;
	line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	line-height: 1.1;
}
h1 {
	font-size: 2.7rem;
}
h2 {
	font-size: 2.5rem;
}
h3 {
	font-size: 2rem;
	font-weight: 500;
}
h4 {
	font-size: 1.3rem;
}
h5 {
	font-size: 1rem;
}

@media only screen and (max-width: 1024px) {
	header nav {
		position: fixed;
		top: -100vh;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: white;
		transition: 1s;
	}

	header .responsive_nav {
		transform: translateY(100vh);
	}
}

/*NOTE: media queries for blogs container*/
.blogs-container {
	column-count: 3; /* Adjust the number of columns as needed */
	column-gap: 16px; /* Space between columns */
}

@media (max-width: 1100px) {
	.blogs-container {
		column-count: 2;
	}
}

@media (max-width: 700px) {
	.blogs-container {
		column-count: 1;
	}
}

